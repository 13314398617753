<template>
  <div>
    <el-card class="card-box">
      <SubTitle title="对公打款认证中"></SubTitle>
      <div class="box">
        <div>
          <span class="font-color-danger">*</span>
          <span class="label">企业名称：</span>
          <span style="font-weight:bold;">{{ enterpriseName }}</span>
        </div>
        <div>
          <span class="font-color-danger">*</span>
          <span class="label">打款状态：</span>
          <span class="font-color-brand">
            {{
            state | enterpriseCertiState
            }}
          </span>
          <el-button
            size="mini"
            type="primary"
            style="margin-left:22px;"
            @click="getEnterpriseInfo"
          >刷新状态</el-button>
        </div>
        <div style="margin-left:96px;" v-if="state === '2'">
          <el-input
            v-model="amount"
            placeholder="请输入对公打款金额进行校验"
            style="width:217px;display:inline-block;line-height: 30px;"
          ></el-input>
          <el-button size="mini" type="primary" style="margin-left:22px;" @click="amountVertify">校验</el-button>
        </div>
        <div style="text-align:center">
          <el-button type="primary" @click="toNext">完成</el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { api } from '/src/api/base';
//查询企业信息
export const enterpriseDescribe = api()('enterprise.describe.json');
// 企业打款认证
const enterpriseCheckPayAmount = api()('enterprise.check.pay.amount.json');
export default {
  components: {
    SubTitle: () => import('/src/components/subTitle.vue')
  },
  props: {
    stepActive: Number
  },
  data () {
    return {
      enterpriseName: localStorage.getItem('enterpriseName') || '',
      state: '',
      amount: null,
      code: ''
    };
  },
  created () {
    this.code =
      this.$route.query.code || window.localStorage.getItem('enterprise');
    this.getEnterpriseInfo();
  },
  methods: {
    async getEnterpriseInfo () {
      const res = await enterpriseDescribe({
        code: this.code
      });
      this.enterpriseName = res.name;
      this.state = res.authenticateState;
    },
    async amountVertify () {
      if (
        !this.amount ||
        this.amount === 0 ||
        this.amount === 1 ||
        this.amount < 0 ||
        this.amount > 1
      ) {
        return this.$message.error('金额不正确！');
      }
      await enterpriseCheckPayAmount({
        code: this.code,
        amount: this.amount
      });
      this.$message.success('企业认证成功');
      setTimeout(() => {
        this.getEnterpriseInfo();
        this.toNext();
      }, 100);
    },
    toNext () {
      this.$emit('update:stepActive', 4);
    }
  }
};
</script>
<style>
.amount-input .el-input__inner {
  /* line-height: 30px; */
}
</style>
<style lang="scss" scoped>
.box {
  margin-left: 31px;
  margin-right: 25px;
  font-size: 14px;
  color: #535353;
  div {
    margin-bottom: 20px;
  }
}
.label {
  display: inline-block;
  margin-right: 16px;
}
</style>
